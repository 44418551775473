<template>
    <navigation-mobile-pos title="Areas" tableHeader="true" />

    <div class="flex flex-col stretcher-content" v-cloak>
        <loading-indicator v-if="!initialized" class="mt-64" />
        <div
            v-if="initialized"
            class="flex-grow px-2"
        >
            <div
                class="mt-4 flex flex-wrap"
                :class="`${isMobileOrderingButtons ? 'flex-row gap-y-1 gap-x-2' : 'flex-col'}`"
            >
                <router-link :to="{ name: 'mobilepos.tables', params: { area_id: area.id } }" class="p-4 flex list-item" v-for="(area, index) in areas" :key="index">
                    <div class="text font-bold list-">{{ area.attributes.name }}</div>
                </router-link>
                <router-link :to="{ name: 'mobilepos.tables', params: { area_id: 'virtualtables' } }" class="p-4 flex list-item flex-1 basis-full" :key="index">
                    <div class="text font-bold">
                        {{ $t('translations.views.mobilepos.pages.areas.virtual-tables') }}
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

// mixins
import TerminalLayoutMixin from '@/mixins/terminal-layout.mixin';

// components
import NavigationMobilePos from '../../../components/NavigationMobilePos'
import LoadingIndicator from '../../../components/LoadingIndicator'

export default {
    name: 'mobilepos.areas',
    components: {
        NavigationMobilePos,
        LoadingIndicator,
    },
    mixins: [TerminalLayoutMixin],
    data () {
        return {
            initialized: false,
            areas: [],
        }
    },
    mounted() {
        this.areas = []
        this.areasGet()
    },

    methods: {

        reload() {
            location.reload()
        },

        async areasGet() {
            this.areas = []
            axios.get('/areas').then(response => {
                this.areas = response.data.data

                // filter tables for clerk
                if (this.clerk && this.clerk.area_ids && this.clerk.area_ids.length > 0) {
                    this.areas = this.areas.filter(area => {
                        return this.clerk.area_ids.indexOf(area.id) >= 0;
                    });
                }

            }).catch(() => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
            }).finally(() => {
                this.initialized = true
            })
        },

        // areaSelect(area) {
        //     this.area = area;
        // },

        areaSelect(area) {
            this.$store.dispatch('transaction/setTable', area)
            this.$router.push({name: 'pos'})
        }
    },
	computed: {
		clerk() {
			return this.$store.getters['transaction/clerk'];
        },

		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
    }
}
</script>
